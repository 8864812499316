ngb-carousel {
    width: 100%;
    padding-bottom: 1rem;

    &:focus {
        outline: none;
    }
    .carousel-control-next {
        padding-right: 0;
        width: unset;
        background-color: transparent;
        // margin-right: -30px;
        .carousel-control-next-icon {
            background-image: none;
            &:after {
                //@include fa-icon;
                //@extend .fas;
                font-size: 2rem;
                font-weight: 900;
                content: fa-content($fa-var-angle-right);
                color: $new-ux-smarkets-gray;
            }
        }
        &:after {
          content: none;
        }
    }
    .carousel-control-prev {
        padding-left: 0;
        width: unset;
        background-color: transparent;
        // margin-left: -30px;
        .carousel-control-prev-icon {
            background-image: none;
            &:after {
                //@include fa-icon;
                //@extend .fas;
                font-size: 2rem;
                font-weight: 900;
                content: fa-content($fa-var-angle-left);
                color: $new-ux-smarkets-gray;
            }
        }
        &:after {
          content: none;
        }
    }

    .carousel-indicators {
        bottom: -1rem;
        li {
            width: 12px;
            height: 12px;
            background-color: transparent;
            border: solid 2px $secondary;
            border-radius: 50%;

            &.active {
                background: $secondary;

                &:hover {
                    background: $new-ux-smarkets-gray;
                }
            }

            &:hover {
                border-color: $new-ux-smarkets-gray;
            }
        }
    }
}