indicador-numerico {
  border-left: 1.25px solid $smarkets-light;
  border-right: 1.25px solid $smarkets-light;

  &:first-child {
    border-left: none;
  }

  &:last-child {
    border-right: none;
  }

  p {
    font-size: 12px;
  }

  h2 {
    color: $new-ux-smarkets-blue-indigo-light !important;
  }

  small {
    display: block;
    font-size: 10px;
  }
}

indicador-grafico {
  .card {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 2.5px solid $smarkets-light;
    .card-title {
      font-size: 12px;
      margin-bottom: 1.5rem;
    }
    .card-body {
      border-top: 2.5px solid $smarkets-light;
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
  }
}

.indicador-tabela {
  &.card {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 2.5px solid $smarkets-light;

    .card-body {
      border-top: 2.5px solid $smarkets-light;
      padding: 0; 
      .table {
        thead {
          tr {
            th {
              color: $new-ux-smarkets-gray;
              text-transform: unset;
              font-weight: normal;
              font-size: 12px;
              border-bottom: 2.5px solid $smarkets-light;
            }
          }
        }
        tbody {
          tr {
            td {
              padding-bottom: .75rem;
              padding-top: .75rem;
              border-bottom: 1.5px solid $smarkets-light;
              font-weight: bold;
              text-transform: uppercase;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}