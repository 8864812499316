ngb-rating {
  span {
    i {
      &.far {
        font-weight: 400;
      }
  
      color: $new-ux-smarkets-gray;
      font-size: 1.5rem;
    }
  }
}

input-rating {
  &.input-rating-sm {
    ngb-rating {
      span {
        i {
          font-size: 1rem;
        }
      }    
    }
  }
  &.input-rating-sm-orange {
    ngb-rating {
      span {
        i {
          &.far {
            font-weight: 400;
          }
      
          color: $new-ux-smarkets-orange;
          font-size: 1rem;
        }
      }
    }
  }
}