.line-clamp {
  display: -moz-box;
  display: -webkit-box;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  /* autoprefixer: ignore next */
  -webkit-box-orient: vertical;
  /* autoprefixer: ignore next */
  -moz-box-orient: vertical;
}

.line-clamp:after {
  content: '...';
  text-align: right;
  bottom: 0;
  right: 0;
  width: 25%;
  display: block;
  position: absolute;
  height: calc(1em * 1.2);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 75%);
}

@supports (-webkit-line-clamp: 1) {
  .line-clamp:after {
    display: none !important;
  }
}

.line-clamp-1 {
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  -webkit-line-clamp: 3;
}

.line-clamp-4 {
  -webkit-line-clamp: 4;
}

.line-clamp-5 {
  -webkit-line-clamp: 5;
}

.text-none {
  text-transform: none !important;
}

.text-small {
  @include media-breakpoint-up(md) {
    font-size: 12px;
  }
}

.text-small-calculate {
  font-size: 77%;
}
