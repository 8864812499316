@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-Light.ttf');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-LightItalic.ttf');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-Italic.ttf');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-SemiBold.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-SemiBoldItalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-Bold.ttf');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-BoldItalic.ttf');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-ExtraBold.ttf');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: "Open Sans";
    src: url('/assets/fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf');
    font-weight: 800;
    font-style: italic;
}