.overflow-y-scroll {
  overflow-y: scroll;
  
  &::-webkit-scrollbar-track {
    background-color: $white;
    border-radius: 10px;
  }
      
  &::-webkit-scrollbar {
    width: 5px;
    background-color: $white;
  }
      
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: $new-ux-smarkets-gray;
  }
}