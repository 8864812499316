.page-item {
  &.active .page-link {
    box-shadow: $pagination-active-box-shadow;
  }
  .page-link,
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0 1.5px;
    border-radius: 50% !important;
    width: 36px;
    height: 36px;
    font-size: $font-size-sm;
    i {
      font-weight: bold;
      &:not(:first-of-type) {
        margin-left: -7.5px;
      }
    }
  }
}

.page-link {
  i {
    color: $primary;
  }
  &.disabled {
    i {
      color: $new-ux-smarkets-gray;
    }
  }
}

.pagination {
  input {
    width: 5rem;
    margin-top: 2px;
  }
  .label {
    margin: auto 0;
    margin-left: 5px;
    margin-right: 5px;
  }
}

.pagination-lg {
  .page-item {
    .page-link,
    span {
      width: 46px;
      height: 46px;
      line-height: 46px;
    }
  }
}

.pagination-sm {
  input {
    margin-top: 0;
  }
  .page-item {
    .page-link,
    span {
      width: 30px;
      height: 30px;
      line-height: 30px;
    }
  }
}

pager {
  .input-group {
    border-radius: 0.25rem;
    border: 1px solid $new-ux-smarkets-gray;

    input {
      height: calc(2.25rem + 2px - 2px);
      padding: 0.375rem 0.75rem;
      border: none;

      &.form-control:focus {
        box-shadow: none;
        background-color: $white;
      }
    }
    .input-group-append,
    .input-group-prepend {
      button {
        background-color: $white;
        border: none !important;
        min-width: unset;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        i {
          color: $new-ux-smarkets-gray;
        }
        &:hover {
          transform: none;
          background-color: $smarkets-lighter;
        }
        &:focus,
        &:active {
          box-shadow: none;
        }
      }
      span {
        border: none;
      }
    }
  }
}
