.tooltip-light {
  opacity: 1 !important;

  .tooltip-inner {
    color: $new-ux-smarkets-gray;
    background-color: $white;
    border: solid 1px $new-ux-smarkets-gray;
  }

  &.bs-tooltip-top {  
    .arrow {
      &::before {
        border-top-color: $white;
      }
    }
  }
  
  &.bs-tooltip-right {
    .arrow {
      &::before {
        border-right-color: $white;
      }
    }
  }
  
  &.bs-tooltip-bottom {
    .arrow {
      &::before {
        border-bottom-color: $white;
      }
    }
  }
  
  &.bs-tooltip-left {
    .arrow {  
      &::before {
        border-left-color: $white;
      }
    }
  }
}