.btn {
    -webkit-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
    min-width: 4rem;

    &:hover {
        @include box-shadow($btn-hover-box-shadow);
        transform: translateY(-1px);
    }

    i {
        font-size: 16px;
    }

    //primary
    &.btn-primary {
        &:hover, &:active {
            background-color: $new-ux-smarkets-blue-light !important;
            border-color: $new-ux-smarkets-blue-light !important;
        }
    }

    &.btn-outline-primary {
        &:hover, &:active {
            background-color: $new-ux-smarkets-blue-light !important;
            border-color: $new-ux-smarkets-blue-light !important;
        }
    }

    &.btn-warning {
      color: $white ;
      &:hover, &:active {
      color: $white !important;
      }
    }

    //primary dark
    &.btn-primary-dark {
        background-color: $primary-dark;
        border-color: $primary-dark;
        color: $white;

        &:hover, &:active {
            background-color: $new-ux-smarkets-blue-indigo-light !important;
            border-color: $new-ux-smarkets-blue-indigo-light !important;
            color: $white !important;
        }
    }

    &.btn-outline-primary-dark {
        background-color: $white;
        border-color: $primary-dark;

        &:hover, &:active {
            background-color: $new-ux-smarkets-blue-indigo-light !important;
            border-color: $new-ux-smarkets-blue-indigo-light !important;
        }
    }

    //indigo
    &.btn-indigo {
        background-color: $indigo;
        color: $white;
    }

    &.btn-fab {
        min-width: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        display: -webkit-inline-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-inline-flex;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        -webkit-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        -moz-box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        i {
            margin: 0;
        }
        &:not(:first-of-type) {
            margin-left: 1rem;
        }
    }

    &.btn-link {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    &.btn-icon {
        min-width: unset;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;

        i {
            font-size: 24px;
            color: $new-ux-smarkets-gray;
        }

        &:hover, &:active {
            i {
                color: $primary;
            }
        }

        &.btn-sm {
            i {
                font-size: 16px;
            }
        }

        &.btn-lg {
            i {
                font-size: 36px;
            }
        }

        &.text-primary {
            i {
                color: $primary;
            }
        }

        &.text-danger {
            i {
                color: $danger;
            }
        }

        &.warning-hover {
            &:hover, &:active {
                i {
                    color: $warning;
                }
            }
        }

        &.danger-hover {
            &:hover, &:active {
                i {
                    font-size: 16px;
                }
            }
        }
    }
}
