// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.
.nav {
    min-height: 60px;
    padding-left: 1rem !important;
}

.nav[role="tablist"] {
    min-height: unset;
    border-bottom: none;
    .nav-item {
        .nav-link {
            border-bottom: none;
            color: $primary;
            &.active {
                border: none;
                font-weight: 700;
                box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.35);
            }
        }
    }
}

.bg-gray {
    
    @media(min-width:1024px) {
        top: $navbar-height;
    }

    position: fixed;
    width: 100%;
    top: 4rem;
    z-index: 100;
    
    background-color: $smarkets-light; // -webkit-box-shadow: inset 0px 3px 5px 0px rgba(175,175,185,1);
    // -moz-box-shadow: inset 0px 3px 5px 0px rgba(175,175,185,1);
    // box-shadow: inset 0px 3px 5px 0px rgba(175,175,185,1);
    .nav-link {
        color: $smarkets-blue-dark !important;
        font-weight: bold;
        text-transform: uppercase;
        i {
            font-size: 1.25rem;
        }
    }
}

.nav-item {
    display: flex;
    align-items: center;   
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .nav-item {
        min-height: 60px;
    }
}

@supports (-ms-accelerator:true) {
    .nav-item {
        min-height: 60px;
    }
}

// .bounds {
//     max-width: unset !important;

//     .nav-tabs, .tab-content {
//         max-width: $page-max-width !important;
//         margin: 0 auto !important;
//     }
// }

.custom-nav-tabs {
    background-color: $smarkets-lighter;
    width: 100%;
    height: $navbar-height;
    
    .nav {
        &.nav-tabs {
            padding-top: 40px;
            padding-left: 0 !important;
        
            .nav-item {
                padding: 0 1rem;
                margin-bottom: 0;
                height: 40px;
                background-color: $smarkets-lighter;
    
                .nav-link {
                    -webkit-box-shadow: none;
                    -moz-box-shadow: none;
                    box-shadow: none;
                    margin-bottom: 0;
                    border-top-left-radius: 0.5rem;
                    border-top-right-radius: 0.5rem;
                    background-color: $white;
        
                    height: 36px;
                    margin-bottom: 4px;

                    color: $new-ux-smarkets-gray;

                    font-size: 16px;
                    padding: .5rem 2rem;
        
                    &.active {
                        height: 40px;
                        margin-bottom: 0;
                        -webkit-box-shadow: none;
                        -moz-box-shadow: none;
                        box-shadow: none;
                        background-color: $smarkets-light;
                    }

                    &.nav-link-block {
                        width: 100%;
                    }
                }
            }
        } 
    }
}

.nav {
    &.nav-pills {
        padding: 0.5rem 0rem;
        
        .nav-item {
            border-color: $gray-300;
            border-style: solid;
            border-radius: 1rem;
            background-color: transparent;
            margin: 0 .5rem;

            :first-of-type {
                margin-left: 0;
            }
            :last-of-type {
                margin-right: 0;
            }

            .nav-link {
                color: $new-ux-smarkets-gray;
                padding: 0.25rem 1rem;
                border-radius: .75rem;

                &.active {
                    background-color: $gray-300;
                    box-shadow: none;
                }
            }
        }
    }

    &.overflow-x-scroll {
        overflow-x: scroll;
        flex-wrap: nowrap;

        &::-webkit-scrollbar-track {
            background-color: $white;
            border-radius: 10px;
        }
              
        &::-webkit-scrollbar {
            height: 5px;
            background-color: $white;
        }
              
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $new-ux-smarkets-gray;
        }
    }
}