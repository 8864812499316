.dropdown-menu {
  &.dropdown-nav-menu {
    padding: 1.5rem 1rem;
    border: 1px solid $new-ux-smarkets-blue-indigo-light;
    margin-top: 2px;
    border-radius: 5px;
    z-index: 1001;

    height: auto;
    max-height: calc(100vh - calc(100% + 5px));
    overflow-x: hidden;

    &::-webkit-scrollbar-track {
      // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      background-color: $white;
      border-radius: 10px;
    }
        
    &::-webkit-scrollbar {
      width: 5px;
      background-color: $white;
    }
        
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $new-ux-smarkets-gray;
    }

    .nav-item {
      height: unset;
  
      &:hover {
        background: rgba(0,0,0,.05);
      }
  
      .nav-link {
        justify-content: left;
        align-items: center;
        padding: 0.75rem 2.5rem 0.75rem 1rem;
  
        color: $new-ux-smarkets-gray;
        font-weight: bold;
        font-size: 14px;
        width: 100%;
        height: 100%;
        text-align: left;
        text-transform: unset;
        text-decoration: none;
  
        &:hover, &:active {
          text-decoration: none;
          transform: none;
        }
  
        i {
          color: $new-ux-smarkets-gray;
          margin-right: 15px;
          font-size: 24px;
          vertical-align: middle;
          text-align: center;
          min-width: 30px;
          margin-right: 1.4rem;
          font-size: 24px;
        }
      }
    }
  }
}

@media(min-width:1024px) {
  .dropdown-menu-center {
    right: auto !important;
    left: 50% !important;
    -webkit-transform: translate(-50%, $navbar-height) !important;
    -o-transform: translate(-50%, $navbar-height) !important;
    transform: translate(-50%, $navbar-height) !important;
  }
}
