$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1440px,
  xxxl: 1680px,
  xxxxl: 1922px,
  xxxxxl: 2240px,
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1380px,
  xxxl: 1600px,
  xxxxl: 1862px,
  xxxxxl: 2180px,
) !default;
